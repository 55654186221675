<template>
  <router-view></router-view>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: 'App',

  created() {
    const token = this.$localStorage.get('rentPulseBackofficeToken');
    if (token) {
      this.axios.defaults.headers.common['x-access-token'] =  token;
      this.fetchCurrentUser();
    }
  },

  methods: {
    ...mapActions([
      'fetchCurrentUser'
    ])
  }
}
</script>

<style scoped lang="scss">
@import './assets/fonts/stylesheet.css';
</style>
