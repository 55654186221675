import Vue from 'vue';
import VueRouter from 'vue-router';
// import store from '../store/store';

const Login = () => import('../views/Login.vue');
const Home = () => import('../views/Home.vue');
const DefaultRouter = () => import('../views/DefaultRouter.vue');
const Tasks = () => import('../views/Tasks.vue');
const Task = () => import('../views/Task.vue');
const Users = () => import('../views/Users.vue');
const User = () => import('../views/User.vue');
const UserObjects = () => import('../views/User/UserObjects.vue');
const UserReviews = () => import('../views/User/UserReviews.vue');
const UserTasks = () => import('../views/User/UserTasks.vue');
const UserHistory = () => import('../views/User/UserHistory.vue');
const CreateTask = () => import('../views/CreateTask.vue');

Vue.use(VueRouter);

const routes = [
    {
        path: '/login',
        name: 'login',
        component: Login
    },
    {
        path: '/',
        name: 'home',
        component: Home,
        redirect: '/tasks',
        children: [
            {
                path: 'tasks',
                name: 'tasks',
                component: DefaultRouter,
                redirect: '/tasks/list',
                children: [
                    {
                        path: 'list',
                        name: 'task-list',
                        component: Tasks
                    },
                    {
                        path: '/tasks/list/:id',
                        name: 'task',
                        component: Task
                    },
                    {
                        path: 'task-create',
                        name: 'task-create',
                        component: CreateTask
                    }
                ]
            },
            {
                path: 'users',
                name: 'users',
                component: DefaultRouter,
                redirect: '/users/list',
                children: [
                    {
                        path: 'list',
                        name: 'user-list',
                        component: Users
                    },
                    {
                        path: ':id',
                        name: 'user',
                        component: User,
                        children: [
                            {
                                path: 'objects',
                                name: 'user-objects',
                                component: UserObjects  
                            },
                            {
                                path: 'reviews',
                                name: 'user-reviews',
                                component: UserReviews
                            },
                            {
                                path: 'tasks',
                                name: 'user-tasks',
                                component: UserTasks
                            },
                            {
                                path: 'history',
                                name: 'user-history',
                                component: UserHistory
                            }
                        ]
                    }
                ]
            },
        ]
    }
];

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
});

router.beforeEach((to, from, next) => {
    const token = localStorage.getItem('rentPulseBackofficeToken');

    if (to.name === 'login' && !token) {
        next();
        return;
    } else if (to.name === 'login' && token){
        next({ name: 'home'});
        return;

    }

    if ( token ) {
        next();
        return;
    } else {
        next({ name: 'login'});
        return;
    }

});

export default router