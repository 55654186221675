export default {
  login: '/auth/support/login',
  me: '/users/me',
  tasks: '/tasks/list',
  task: '/tasks',
  closeTask: '/tasks/close/',
  clients: '/clients/list',
  clinetInfo: '/clients/{id}/info',
  clientBan: '/clients/ban',
  clientObjects: '/clients/{id}/objects',
  updateClientObjects: '/clients/objects',
  clientReviews: '/clients/{id}/reviews',
  updateClientReviews: '/clients/reviews',
  clientTasks: '/clients/tasks/list',
  createTask: '/tasks/',
  clientHistory: '/clients/{id}/history'
};