import Vue from 'vue'
import App from './App.vue'
import store from './store/store.js';
import router from './router/router';
import InlineSvg from 'vue-inline-svg';
import axios from 'axios';
import VueAxios from 'vue-axios';
import VeeValidate from 'vee-validate';
import VueLocalStorage from 'vue-localstorage';
import "normalize.css";
import VuePdfApp from "vue-pdf-app";
import "vue-pdf-app/dist/icons/main.css";

Vue.config.productionTip = false

Vue.component('inline-svg', InlineSvg);
Vue.component('vue-pdf-app', VuePdfApp);


Vue.use(VueAxios, axios);
Vue.use(VeeValidate);
Vue.use(VueLocalStorage);
Vue.use(require('vue-moment'));

Vue.axios.defaults.baseURL = process.env.VUE_APP_API_URL;

axios.interceptors.response.use(response => {
  return response;
},
function(error) {
  if (error.response.data.message === 'jwt expired') {
    localStorage.removeItem('rentPulseBackofficeToken');
    router.push({path: '/login'});
  }
  return Promise.reject(error.response);
});

new Vue({
  store,
  router,
  render: h => h(App),
}).$mount('#app')
