import Vue from 'vue';
import Vuex from 'vuex';
import axios from 'axios';
import apiRoutes from '../consts/apiRoutes';
import task from "./modules/task";

Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
      task
    },
    state: {
      user: null
    },
    actions: {
      async fetchCurrentUser({ commit }) {
        const response = await axios.get(apiRoutes.me);
        commit('setUser', response.data);
      }
    },
    mutations: {
      setUser(state, payload) {
        state.user = payload;
      }
    }
});