const state = {
  isVisibleCreateTaskModal: false,
  task: null,
  predefinedPhone: null
};

const mutations = {
  showCreateTaskModal(state, phone) {
    if (phone) {
      state.predefinedPhone = phone;
    }
    state.isVisibleCreateTaskModal = true;
  },

  hideCreateTaskModal(state) {
    state.predefinedPhone = null;
    state.isVisibleCreateTaskModal = false;
  },

  setTask(state, task) {
    state.task = task;
  }
};

export default {
  namespaced: true,
  state,
  mutations
};